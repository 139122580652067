import styled from 'styled-components';

interface IconProps {
  size?: number;
}

interface NotificationProps {
  show: boolean;
}

interface ToogleButtonProps {
  active: boolean;
}

export const Container = styled.header`
  display: flex;
  flex-wrap: wrap; /* Permite que os elementos quebrem linha */
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  min-height: 80px;
  margin-bottom: 20px;
  position: relative;
  padding: 10px; /* Melhor espaçamento */

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    padding: 10px;
  }
`;

export const TimerAndUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 10px 20px;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 0.5rem;
  }
`;
export const TitleAndTimerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
`;

export const Icon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
`;

export const VerticalLineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const VerticalLine = styled.div`
  width: 3px;
  height: 60px;
  background: #d1d1d1;
  border-radius: 10px;
  @media (max-width: 805px) {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  width: 200px;

  .title {
    font-family: 'Graphik', sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #838383;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const LoginDetail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 12px;
  justify-content: flex-start;

  p {
    font-size: 1rem;
    color: #9fa39d;
  }

  .establishment-name {
    font-weight: bold;
    font-size: 1.1rem;
    color: #222;
  }

  button {
    height: 45px;
    background-color: #e1ff2b;
    border: none;
    font-size: 0.75rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 8px;
    border-radius: 15px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #c6e128;
      transform: scale(1.05);
    }

    &:active {
      background-color: #b0c920;
    }
  }

  .star {
    color: #6b7280;
    display: none;

    &:hover {
      color: #eab308;
    }
  }

  @media (max-width: 1120px) {
    p {
      display: none;
    }
  }

  @media (max-width: 600px) {
    .star {
      display: flex;
    }

    .starText {
      display: none;
    }

    button {
      background-color: #f8f8f8;

      &:hover {
        background-color: #f8f8f8;
        transform: scale(1.05);
      }
    }
  }
`;

export const LoggedGymVerticalLine = styled.div`
  width: 2px;
  height: 60px;
  background: #e0e0e0;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const NotifContainer = styled.div`
  min-width: 350px;
  width: 350px;
  position: absolute;
  right: 0;

  @media (max-width: 800px) {
    position: relative;
  }

  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const NotificationContainer = styled.div<NotificationProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  ${({ show }) =>
    !show &&
    `
    visibility: hidden;
  `}

  .notification {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 40px;
    background-color: #fff;
    padding: 0 1rem;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  }

  .notification-text-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-family: 'Graphik', sans-serif;
    padding: 0 1rem;
    cursor: pointer;
    margin-right: 36px;

    .notification-title {
      font-family: 'Graphik', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;
      color: #434343;
    }

    .notification-subtitle {
      font-family: 'Graphik', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 11px;
      color: #838383;
    }

    .icon-close {
      margin-right: 6px;
    }

    @media screen and (max-width: 998px) {
      display: none;
    }
  }

  @media screen and (max-width: 998px) {
    .notification-title,
    .notification-subtitle,
    .icon-close {
      display: none;
    }
    width: auto;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0 1rem;

  .btn-container {
    display: flex;
    background-color: transparent;
    border-radius: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
    padding: 5px;
    height: 60px;
  }
`;

export const ToggleButton = styled.button<ToogleButtonProps>`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 50px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Graphik', sans-serif;
  color: #434343;
  ${({ active }) =>
    active &&
    `
    background-color: #fff;
    border-radius: 20px;
  `}
  transition: all 0.2s ease-out;

  :hover {
    opacity: 0.8;
  }
`;
