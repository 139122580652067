import React, { FC } from 'react';
import { FaStar } from 'react-icons/fa';
import { DigitalClock } from '@/components/common/DigitalClock';
import Logo from '@/constant/logo';

import { ImCross } from 'react-icons/im';
import { BsPersonCircle } from 'react-icons/bs';

import NotificationBox from '../NotificationBox/NotificationBox';

import { HeadingProps } from './Heading.types';
import useHeadingController from './Heading.controller';

import {
  ButtonContainer,
  Container,
  Icon,
  IconContainer,
  LoggedGymVerticalLine,
  LoginDetail,
  NotifContainer,
  NotificationContainer,
  TimerAndUserContainer,
  TitleAndTimerContainer,
  TitleContainer,
  ToggleButton,
  VerticalLine,
  VerticalLineContainer,
} from './styles';

export const Heading: FC<HeadingProps> = ({
  showNotification = false,
  showToggle = true,
  setCloseNotification,
  onClickNotification,
  showEstablishmentName = true,
}) => {
  const {
    establishment,
    establishmentGroup,
    navigation,
    router,
    photo,
    name,
    notificationMessage,
    setNotificationMessage,
    isFranchise,
    addToFavorite,
    isOwner,
  } = useHeadingController();

  return (
    <Container>
      <TimerAndUserContainer>
        <TitleAndTimerContainer>
          <IconContainer>
            {photo ? (
              <Icon src={photo} alt={name} title={name} />
            ) : (
              <BsPersonCircle size={40} color="#737373" title={name} />
            )}
          </IconContainer>
          <DigitalClock />
        </TitleAndTimerContainer>

        <VerticalLineContainer>
          <VerticalLine />
          <TitleContainer>
            <Logo />
            <span className="title">Sistema para Academias</span>
          </TitleContainer>
        </VerticalLineContainer>
      </TimerAndUserContainer>
      {showEstablishmentName && (
        <LoginDetail>
          <p>Você está logado na:</p>
          <span className="establishment-name" data-testid="gym-name">
            {establishment?.name || establishmentGroup?.name || 'Academia'}
          </span>

          <button onClick={addToFavorite}>
            <FaStar className={`star w-5 h-5`} />
            <span className="starText">Favoritar</span>
          </button>
        </LoginDetail>
      )}
      {notificationMessage.title.length > 0 && (
        <NotifContainer>
          <NotificationBox
            title={notificationMessage.title}
            linkText={notificationMessage.message}
            handleClose={setNotificationMessage}
            content={''}
            onClick={() => {}}
          />
        </NotifContainer>
      )}
      {showNotification && (
        <NotificationContainer
          show={showNotification}
          onClick={() => navigation('schedule')}>
          <div className="notification">
            <BsPersonCircle size={40} color="#D8F32E" />
            <div
              className="notification-text-container"
              onClick={onClickNotification}>
              <span className="notification-title">
                Nova solicitação de check-in
              </span>
              <span className="notification-subtitle">
                Clique para ver agenda
              </span>
            </div>
            <ImCross
              color="#818181"
              size={15}
              onClick={setCloseNotification}
              className="icon-close"
              style={{ cursor: 'pointer' }}
            />
          </div>
        </NotificationContainer>
      )}
      {showToggle && !isFranchise && (
        <ButtonContainer>
          <div className="btn-container">
            <ToggleButton
              onClick={() => navigation('/')}
              active={router?.asPath === '/'}>
              Home
            </ToggleButton>
            <ToggleButton
              onClick={() => navigation('/schedule')}
              active={router?.asPath === '/schedule'}>
              Agenda
            </ToggleButton>
          </div>
        </ButtonContainer>
      )}
    </Container>
  );
};
